import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(application)/[locale]/layout.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/registry.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Layout/DashboardLayout/DashboardLayout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Layout/MainLayout/MainLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/src/context/CurrentUserContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterRefProvider"] */ "/vercel/path0/src/context/FooterRefContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderRefProvider"] */ "/vercel/path0/src/context/HeaderRefContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LightboxProvider"] */ "/vercel/path0/src/context/LightboxContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigationProvider"] */ "/vercel/path0/src/context/MobileNavigationContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/vercel/path0/src/context/ModalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageOverlayProvider"] */ "/vercel/path0/src/context/PageOverlayContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactIntlProvider"] */ "/vercel/path0/src/providers/ReactIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/vercel/path0/src/providers/SWRProvider.tsx");
